<template>
  <div class="view">
    <div class="header">
      <div class="text">充值号码</div>
      <div class="number-wrapper">
        <input class="phone-input" type="text" v-model="phone" placeholder="请输入充值号码" />
        <div class="clear-box">
          <div class="clear" v-if="phone" @click="clear()">
            <van-icon name="cross" color="#ffffff" size="14" />
          </div>
        </div>
        <img class="contact" src="@/assets/life-service/contact.png" alt="" />
      </div>
    </div>

    <div class="main">
      <div class="tab-bar">
        <div :class="{ 'tab-box': true, 'tab-box_active': tabIndex == 0 }" @click="tabClick(0)">
          <div class="tab">移动</div>
          <div class="line"></div>
        </div>
        <div :class="{ 'tab-box': true, 'tab-box_active': tabIndex == 1 }" @click="tabClick(1)">
          <div class="tab">电信</div>
          <div class="line"></div>
        </div>
        <div :class="{ 'tab-box': true, 'tab-box_active': tabIndex == 2 }" @click="tabClick(2)">
          <div class="tab">联通</div>
          <div class="line"></div>
        </div>
      </div>

      <div class="product-list">
        <div
          :class="{ product: true, product_active: proIndex == index }"
          v-for="(item, index) in list"
          :key="item.id"
          @click="productClick(index)"
        >
          <div class="price">
            {{ item.price }}
            <span class="aaa">元</span>
          </div>
          <div class="original">原价：{{ item.original }}元</div>
        </div>
      </div>
    </div>

    <div class="btn-cell" @click="cellBtnClick('order', 1)">
      <img class="icon" src="@/assets/life-service/order.png" alt="" />
      <div class="name">交易记录</div>
      <van-icon name="arrow" color="#999999" />
    </div>

    <div class="btn-cell" @click="cellBtnClick('phone-book')">
      <img class="icon" src="@/assets/life-service/phone-book.png" alt="" />
      <div class="name">号码管理</div>
      <van-icon name="arrow" color="#999999" />
    </div>

    <div class="recharge-btn" @click="rechargeClick()">立即充值</div>

    <div class="cut-line"></div>

    <img class="explain" src="@/assets/life-service/phone-recharge_explain.png" alt="" />
  </div>
</template>

<script>
export default {
  name: '',
  components: {},

  props: {},

  data() {
    return {
      phone: '',
      tabIndex: 0,
      proIndex: 0,
      list: [
        {
          id: 1,
          price: 93.5,
          original: 100
        },
        {
          id: 2,
          price: 187.0,
          original: 200
        }
      ]
    }
  },

  filters: {
    phoneFilter(value) {
      const obj = packageType.find((item) => item.value === value)
      return obj ? obj.label : '未知'
    }
  },
  computed: {},
  watch: {},

  created() {},

  methods: {
    clear() {
      this.phone = ''
    },

    tabClick(index) {
      this.tabIndex = index
    },

    productClick(index) {
      this.proIndex = index
    },

    cellBtnClick(type, mode) {
      return
      let path = ''
      let query = {}

      if (type == 'order') {
        path = '/life-service/order'
        query = { type: mode }
      } else if (type == 'phone-book') {
        path = '/life-service/phone-book'
      }

      this.$router.push({
        path: path,
        query: query
      })
    },

    rechargeClick() {
      this.$toast('即将开通')
    }
  }
}
</script>

<style lang="scss" scoped>
.view {
  min-height: 100vh;
  background-color: #f6f6f8;

  .header {
    height: 570px;
    padding: 40px;
    background: url('~@/assets/life-service/phone-recharge_header_bg.png') no-repeat 0 0 / 100% auto;

    .text {
      margin-top: 28px;
      font-size: 28px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
    }

    .number-wrapper {
      display: flex;
      align-items: center;
      height: 98px;
      margin-top: 24px;
      border-bottom: 2px solid rgba(255, 255, 255, 0.5);

      .phone-input {
        width: 480px;
        height: 96px;
        font-size: 68px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 96px;
        background-color: transparent;

        &::-webkit-input-placeholder {
          // font-size: 60px;
          // color: #ffffff;
        }
      }

      .clear-box {
        width: 40px;
        margin-left: 12px;

        .clear {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: rgba(255, 255, 255, 0.2);
        }
      }

      .contact {
        width: 44px;
        height: 52px;
        margin-left: 72px;
      }
    }
  }

  .main {
    width: 710px;
    margin: -278px auto 0;
    padding: 40px 28px 40px;
    background-color: #ffffff;
    border-radius: 16px;

    .tab-bar {
      display: flex;
      justify-content: space-around;

      .tab-box {
        display: flex;
        flex-direction: column;
        align-items: center;

        .tab {
          font-size: 36px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #999999;
          line-height: 50px;
        }

        .line {
          display: none;
        }
      }

      .tab-box_active {
        .tab {
          color: #3a89ff;
        }

        .line {
          display: block;
          width: 44px;
          height: 8px;
          margin-top: 6px;
          background: #3a89ff;
          border-radius: 4px;
        }
      }
    }

    .product-list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 40px;

      .product {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        width: 314px;
        height: 138px;
        margin-bottom: 20px;
        padding: 16px;
        background: #f5faff;
        border-radius: 12px;
        color: #3a89ff;

        .price {
          font-size: 52px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
        }

        .original {
          font-size: 24px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          text-decoration: line-through;
        }
      }

      .product_active {
        background: #3a89ff;
        color: #ffffff;
      }
    }
  }

  .btn-cell {
    display: flex;
    align-items: center;
    width: 710px;
    height: 100px;
    margin: 20px auto 0;
    padding: 30px 40px;
    background: #ffffff;
    border-radius: 16px;

    .icon {
      width: 34px;
      height: 34px;
    }

    .name {
      flex: 1;
      margin-left: 16px;
      font-size: 28px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
  }

  .recharge-btn {
    width: 710px;
    height: 96px;
    margin: 44px auto;
    background: #3a89ff;
    border-radius: 48px;
    font-size: 32px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 96px;
    text-align: center;
  }

  .cut-line {
    width: 750px;
    height: 2px;
    background-color: #e9e9e9;
  }

  .explain {
    width: 710px;
    margin: 38px 20px;
  }
}
</style>
